'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { markdownToHtml } from '@/helpers/string';

import './index.css';

export const LineFormations = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h3';

  return blok.lineFormationsItem?.length > 0 ? (
    <div
      className={classNames('line-formations', {
        'line-formations--center-text': blok.centerText,
        'line-formations--colored-dots': blok.coloredDots,
      })}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <div className="line-formations__line" />

        <div className="line-formations__dots">
          {blok.lineFormationsItem.map((item) => {
            return <div key={item._uid} className="line-formations__dot" />;
          })}
        </div>

        <ul
          className="line-formations__wrap"
          style={{ '--nb-items': blok.lineFormationsItem.length }}
        >
          {blok.lineFormationsItem.map((item) => {
            return (
              <li
                key={item._uid}
                {...storyblokEditable(item)}
                className={classNames('line-formations__card', {
                  'line-formations__card--use-icon': item.useIcon,
                })}
              >
                <div className="line-formations__card__image">
                  <Image
                    sizes="(max-width: 768px) 80vw, 270px"
                    image={item.image}
                  />
                </div>

                <TitleEl className="line-formations__card__title">
                  {item.title}
                </TitleEl>

                <p
                  className="line-formations__card__content"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(item.content),
                  }}
                />
              </li>
            );
          })}
        </ul>
      </MaxWidth>
    </div>
  ) : null;
};

'use client';

import { storyblokEditable } from '@storyblok/react';

import * as Icons from '@/components/icons';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { hasText, render } from '@/helpers/storyblok/rich-text';

import './index.css';

import { Button } from '@/components/button';
import { Link } from '@/components/link';

import { isLink } from '@/helpers/storyblok/is-link';
import { markdownToHtml } from '@/helpers/string';
import { formatCurrency } from '@/helpers/text';

export const TopFormation = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h2';

  const link = blok.cta?.[0]?.link;

  return (
    <div className="top-formation" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="top-formation__wrap">
          <div className="top-formation__image">
            <div className="top-formation__plus-sign">
              <Icons.PlusSign />
            </div>

            <Image sizes="(max-width: 768px) 80vw, 40vw" image={blok.image} />
          </div>

          <div className="top-formation__text">
            {blok.supTitle ? (
              <p className="top-formation__text__sup-title">{blok.supTitle}</p>
            ) : null}

            <TitleEl className="top-formation__text__title">
              {isLink(link) ? (
                <Link link={link}>{blok.title}</Link>
              ) : (
                blok.title
              )}
            </TitleEl>

            {hasText(blok.description) ? (
              <div className="top-formation__text__description">
                {render(blok.description)}
              </div>
            ) : null}

            <div className="top-formation__text__price">
              {blok.price ? (
                <strong>
                  {formatCurrency(blok.price)}
                  <sub> HT</sub>
                </strong>
              ) : null}

              {blok.priceTag ? (
                <p className="top-formation__text__price__tag">
                  {blok.priceTag}
                </p>
              ) : null}
            </div>

            {hasText(blok.subPrice) ? (
              <div className="top-formation__text__sub-price">
                {render(blok.subPrice)}
              </div>
            ) : null}

            {blok.cta?.length > 0 ? (
              <div className="top-formation__text__button">
                {blok.cta.map((item) => {
                  return (
                    <Button
                      key={item._uid}
                      as="div"
                      theme="primary"
                      dangerouslySetInnerHTML={{
                        __html: markdownToHtml(item.title),
                      }}
                      {...storyblokEditable(item)}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';

import ReactPlayer from 'react-player';

import { Button } from '@/components/button';

export const VideoText = ({ blok }) => {
  const [mountEmbed, setMountEmbed] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const player = useRef(null);

  const handleReady = () => {
    setIsReady(true);
  };

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  const videoUrl = blok.videoItem?.url || '';

  const youtubeIdMatch = videoUrl && videoUrl.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <div {...storyblokEditable(blok)} className="video-text">
      <MaxWidth>
        <div
          className={classNames('video-text__wrap', {
            'video-text__wrap--reverse': blok.reversed === true,
          })}
        >
          <div className="video-text__wrap__text">
            <h2
              className="video-text__wrap__text__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />

            <hr />

            <div className="video-text__wrap__text__description">
              {render(blok.text)}
            </div>
            {blok.cta.map((item) => {
              return (
                <Button
                  key={item._uid}
                  link={item.link}
                  theme="primary"
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(item.title),
                  }}
                  {...storyblokEditable(item)}
                />
              );
            })}
          </div>
          {blok.videoItem ? (
            <div
              className={classnames('aspect-ratio', {
                'video--fullscreen': isFullScreen,
              })}
              ref={playerContainer}
            >
              <div className="aspect-ratio__wrap">
                <div className="aspect-ratio__wrap__wrap">
                  <div
                    className={classnames('video-text__video', {
                      'video--ready': isReady,
                      'video--playing': isPlaying,
                      'video--started': isStarted,
                      'video--fullscreen': isFullScreen,
                    })}
                  >
                    <div className="video__player" ref={videoPlayer}>
                      {slice.primary.mobileImage ? (
                        <div className="video__player__cover">
                          <img
                            src={slice.primary.mobileImage.url}
                            alt={slice.primary.mobileImage.alt ?? ''}
                          />
                        </div>
                      ) : null}

                      <div className="video-text__video__wrap">
                        {mountEmbed && (
                          <ReactPlayer.default
                            className="video__player__embed"
                            ref={player}
                            url={videoUrl}
                            onStart={handleReady}
                            playing={isPlaying}
                            loop
                            controls={false}
                            muted={isMuted}
                            width="100%"
                            height={`300%`}
                            config={{
                              youtube: {
                                preload: true,
                                embedOptions: {
                                  host: 'https://www.youtube-nocookie.com',
                                },
                                playerVars: {
                                  playsinline: 1,
                                  modestbranding: 1,
                                  showinfo: 0,
                                  loop: 1,
                                  playlist: youtubeId,
                                },
                              },
                            }}
                          />
                        )}

                        <div className="video__control">
                          <button
                            className="video__control__play-pause"
                            onClick={(e) => handlePlayPause(e)}
                            aria-label={isPlaying ? 'Pause' : 'Play'}
                            tabIndex={isStarted ? null : -1}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="video__fullscreen"
                      onClick={handleFullScreen}
                    >
                      <div className="video__fullscreen__img">
                        <img
                          src="/icons/fullscreen-icon.png"
                          srcSet="/icons/fullscreen-icon.svg"
                          alt=""
                          draggable={false}
                        />
                      </div>
                      <span className="visually-hidden">Plein écran</span>
                    </button>

                    <div
                      className="video__wrapper"
                      onClick={
                        isStarted && isPlaying
                          ? (event) => {
                              handlePlayPause(event);
                            }
                          : null
                      }
                    >
                      <div className="video__wrap">
                        <div className="video__play">
                          <button
                            onClick={
                              isStarted
                                ? (event) => {
                                    handlePlayPause(event);
                                  }
                                : handleStart
                            }
                            tabIndex={isStarted ? -1 : null}
                          >
                            <div className="video__play__img">
                              <img
                                src="/icons/play.png"
                                srcSet="/icons/play.svg"
                                alt=""
                                draggable={false}
                              />
                            </div>
                            <span className="visually-hidden">Play</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

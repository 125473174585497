'use client';

import { storyblokEditable } from '@storyblok/react';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useState } from 'react';

import { MaxWidth } from '@/components/max-width';

import { FutureCoursesItem } from './item';

import './index.css';

import classNames from 'classnames';

export const FutureCourses = ({ blok }) => {
  const [loaded, setLoaded] = useState(false);

  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 1,
      spacing: 32,
    },
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 'auto',
          spacing: 16,
        },
      },
    },
    mode: 'snap',
    created() {
      setLoaded(true);
    },
  });

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames('future-courses', {
        'future-courses--loaded': loaded,
      })}
    >
      <MaxWidth>
        <div className="future-courses__slider keen-slider" ref={sliderRef}>
          {blok.futureCoursesItems.map((item) => {
            return <FutureCoursesItem key={item._uid} blok={item} />;
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import { useKeenSlider } from 'keen-slider/react';

import { Image } from '@/components/image';

import 'keen-slider/keen-slider.min.css';
import './index.css';

export const SliderRessources = ({ blok }) => {
  const [sliderRef] = useKeenSlider({
    loop: true,
    slides: {
      perView: 'auto',
      spacing: 24,
      origin: 'center',
    },

    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 'auto',
          spacing: 16,
          origin: 'center',
        },
      },
    },

    mode: 'snap',
  });

  return (
    <div className="slider-ressources" {...storyblokEditable(blok)}>
      <div className="slider-ressources__max-width">
        <div ref={sliderRef} className="slider-ressources-content keen-slider">
          {blok.images.map((item, index) => {
            const desktopImage = blok.images[index];
            const mobileImage = blok.mobileImages[index];

            return (
              <div
                className="slider-ressources__item keen-slider__slide"
                key={item.id}
                {...storyblokEditable(item)}
              >
                <Image
                  className="slider-ressources__item__picture mobile-only"
                  image={mobileImage}
                  sizes="400px"
                />

                <Image
                  className="slider-ressources__item__picture desktop-only"
                  image={desktopImage}
                  sizes="700px"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';

import { CountdownForm } from '@/components/countdown-form';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';
import { markdownToHtml } from '@/helpers/string';

export const CountdownHeroClient = ({ storyFullSlug, locale, blok }) => {
  const TitleEl = blok.level || 'h2';

  return (
    <div {...storyblokEditable(blok)} className="countdown-hero">
      <div className="countdown-hero__content">
        <MaxWidth>
          {blok.title ? (
            <TitleEl
              className="countdown-hero__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />
          ) : null}

          {blok.description ? (
            <p className="countdown-hero__description">{blok.description}</p>
          ) : null}
        </MaxWidth>

        {blok.countdownTitle?.length > 0 ? (
          <div className="countdown-hero__content__line" />
        ) : null}

        <div className="countdown-hero__content__background">
          <div className="countdown-hero__content__background__gradient" />
          {isImage(blok.image) ? (
            <div
              className={classNames(
                'countdown-hero__content__background__image',
                {
                  'desktop-only': isImage(blok.imageMobile),
                },
              )}
            >
              <Image sizes="700px" image={blok.image} />
            </div>
          ) : null}

          {isImage(blok.imageMobile) ? (
            <div
              className={classNames(
                'countdown-hero__content__background__image',
                {
                  'mobile-only': isImage(blok.image),
                },
              )}
            >
              <Image sizes="450px" image={blok.imageMobile} />
            </div>
          ) : null}
        </div>
      </div>

      {blok.countdownTitle?.length > 0 ? (
        <MaxWidth className="countdown-hero__form-max-width">
          <div className="countdown-hero__form">
            <CountdownForm
              anchor={blok.anchor}
              date={new Date(blok.countdownDate)}
              title={blok.countdownTitle}
              subtitle={blok.countdownSubtitle}
              form={blok.form?.[0]}
            />
          </div>
        </MaxWidth>
      ) : null}
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import React from 'react';

import { InlineForm } from '@/components/inline-form';
import { MaxWidth } from '@/components/max-width';

import { markdownToHtml } from '@/helpers/string';

import './index.css';

export const LandingFormClient = ({ storyFullSlug, locale, blok }) => {
  const TitleEl = blok.titleLevel || 'h3';

  const form = blok.form?.[0];

  return (
    <div {...storyblokEditable(blok)} className="landing-form">
      <MaxWidth>
        <div className="landing-form__wrap">
          {blok.title ? (
            <TitleEl
              className="landing-form__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />
          ) : null}

          <div className="landing-form__form">
            <InlineForm
              emailInputId={form?.emailInputId}
              emailLabel={form?.emailLabel}
              submitLabel={form?.submitLabel}
              submitLabelMobile={form?.submitLabelMobile}
              successMessage={form?.successMessage}
              errorMessage={form?.errorMessage}
            />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

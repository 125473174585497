'use client';

import React, { createContext, useContext } from 'react';

import { DEFAULT_LOCALE } from '@/config/index.js';

/**
 * @typedef {import('@/config/index.js').Locale} Locale
 */

/**
 * @typedef {Object<string, Object[]>} FilteredByItem
 */

/**
 * @typedef {Object} Data
 *
 * @property {Locale} locale
 * @property {Object} story
 * @property {Object.<string, Object>} layouts
 *
 * @property {Object[]} [artists]
 * @property {Object[]} [editions]
 * @property {Object[]} [exhibitions]
 * @property {Object[]} [fairs]
 * @property {Object[]} [news]
 *
 * @property {Object<string, FilteredByItem>} [filteredBy]
 */

/**
 * @type {Data}
 */
const initialData = {
  locale: DEFAULT_LOCALE,
  story: {},
  layouts: {},
};

const Data = createContext(initialData);

/**
 * @returns {Data}
 */
export const useData = () => {
  return useContext(Data);
};

/**
 * @param {Object} props
 * @param {Data} props.value
 * @param {any} props.children
 */
export const DataProvider = ({ value, children }) => {
  return <Data.Provider value={value}>{children}</Data.Provider>;
};

export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

export const getCountdownValues = (timeLeft) => {
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const COUNTDOWN_VALUES = [
  {
    key: 'days',
    label: {
      en: 'Day',
      fr: 'Jour',
    },
    labelPlural: {
      en: 'Days',
      fr: 'Jours',
    },
  },
  {
    key: 'hours',
    label: {
      en: 'Hour',
      fr: 'Heure',
    },
    labelPlural: {
      en: 'Hours',
      fr: 'Heures',
    },
  },
  {
    key: 'minutes',
    label: {
      en: 'Minute',
      fr: 'Minute',
    },
    labelPlural: {
      en: 'Minutes',
      fr: 'Minutes',
    },
  },
  {
    key: 'seconds',
    label: {
      en: 'Second',
      fr: 'Seconde',
    },
    labelPlural: {
      en: 'Seconds',
      fr: 'Secondes',
    },
  },
];

import { getLocale, getStory } from '@/helpers/server/context';

import { CountdownFormClient } from './client';

import './index.css';
import './timer.css';

export const CountdownForm = ({ ...props }) => {
  return <CountdownFormClient {...props} />;
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { WhiteArrow } from '@/components/icons';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { isImage } from '@/helpers/image';
import { hasText, render } from '@/helpers/storyblok/rich-text';

import './index.css';

const QA_INDEX_ATTRIBUTE_NAME = 'qa-index';

const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number.toString();
};

const QuestionsAnswersItem = ({
  displayNumbers,
  item,
  index,
  TitleEl,
  faqIndex,
  handleClick,
}) => {
  const hasAnswer = hasText(item.answer);

  return (
    <div
      {...storyblokEditable(item)}
      className={classnames(
        'qa__content__item',
        index % 2 === 0 ? 'qa__content__item--odd' : 'qa__content__item--even',
      )}
    >
      <div
        className={classnames('qa__content__item__header', {
          'qa__content__item__header--no-qa': !hasAnswer,
        })}
      >
        {item.question ? (
          <div className="qa__content__item__header__title">
            {displayNumbers ? (
              <span className="qa__content__item__header__title__number">
                {formatNumber(index + 1)}
              </span>
            ) : null}

            <TitleEl
              className="qa__content__item__header__title__link"
              {...{
                [QA_INDEX_ATTRIBUTE_NAME]: index,
              }}
            >
              {hasAnswer ? (
                <button onClick={() => handleClick(index)}>
                  {item.question}
                </button>
              ) : (
                item.question
              )}
            </TitleEl>
          </div>
        ) : null}

        {hasAnswer ? (
          <div className="qa__content__item__header__icon">
            <motion.div
              animate={{
                rotate: index === faqIndex ? 90 : 0,
              }}
              transition={{ duration: 0.3, ease: 'easeOut' }}
            >
              <WhiteArrow />
            </motion.div>
          </div>
        ) : null}
      </div>

      {hasAnswer ? (
        <motion.div
          initial={{ height: 0 }}
          animate={{
            height: index === faqIndex ? 'auto' : 0,
          }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
          className={classnames('qa__content__item__content', {
            'qa__content__item__content--current': index === faqIndex,
          })}
        >
          <div className="qa__content__item__content__inner">
            {isImage(item.image) ? (
              <div className="qa__content__item__content__image">
                <Image image={item.image} sizes="200px" />
              </div>
            ) : null}

            <div>{render(item.answer)}</div>
          </div>
        </motion.div>
      ) : null}
    </div>
  );
};

export const QuestionsAnswers = ({ blok }) => {
  const [faqIndex, setFaqIndex] = useState(null);

  const handleClick = (index) => {
    setFaqIndex((current) => {
      return index !== current ? index : null;
    });
  };

  const TitleEl = blok.titleLevel || 'h3';

  return (
    <div {...storyblokEditable(blok)} className="qa">
      <MaxWidth>
        <div className="qa__wrap">
          <div className="qa__content__container qa__content__container--open">
            <div className="qa__content">
              {(blok.items || []).map((item, index) => {
                return (
                  <QuestionsAnswersItem
                    key={item._uid}
                    displayNumbers={blok.displayNumbers}
                    item={item}
                    index={index}
                    TitleEl={TitleEl}
                    faqIndex={faqIndex}
                    handleClick={handleClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export const RedCheck = () => {
  return (
    <img
      src="/icons/red-check.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowRolledUp = () => {
  return (
    <img
      src="/icons/arrow-rolled-up.svg"
      width="20"
      height="20"
      alt=""
      draggable="false"
    />
  );
};

export const GreenCheck = () => {
  return (
    <img
      src="/icons/green-check.svg"
      width="15"
      height="15"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowLeftCircle = () => {
  return (
    <img
      src="/icons/arrow-left-circle.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowRightCircle = () => {
  return (
    <img
      src="/icons/arrow-right-circle.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowLeftCircleWhite = () => {
  return (
    <img
      src="/icons/arrow-left-circle-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowRightCircleWhite = () => {
  return (
    <img
      src="/icons/arrow-right-circle-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const Quotes = ({ ...rest }) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8014 6.32253C8.60171 9.54668 5.71306 13.2993 5.13542 17.5805C4.23618 24.2452 10.4092 27.5074 13.537 24.5763C16.6647 21.6453 14.7898 17.9249 12.525 16.908C10.2602 15.891 8.87514 16.2452 9.11674 14.8862C9.35834 13.5273 12.5805 9.75941 15.4113 8.00468C15.5991 7.85048 15.6706 7.55128 15.4898 7.32428C15.3708 7.17501 15.1375 6.88225 14.7898 6.44596C14.4858 6.06445 14.1946 6.07873 13.8014 6.32253Z"
        fill="var(--color)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4906 6.32253C22.291 9.54668 19.4023 13.2993 18.8246 17.5805C17.9254 24.2452 24.0984 27.5074 27.2262 24.5763C30.354 21.6453 28.4791 17.9249 26.2142 16.908C23.9494 15.891 22.5643 16.2452 22.806 14.8862C23.0476 13.5273 26.2698 9.75941 29.1006 8.00468C29.2884 7.85048 29.3598 7.55128 29.1789 7.32428C29.06 7.17501 28.8267 6.88225 28.4791 6.44596C28.1751 6.06445 27.8838 6.07873 27.4906 6.32253Z"
        fill="var(--color)"
      />
    </svg>
  );
};

export const UpArrow = () => {
  return (
    <img
      src="/icons/up-arrow.svg"
      width="24"
      height="24"
      alt=""
      draggable="false"
    />
  );
};

export const DownArrow = () => {
  return (
    <img
      src="/icons/down-arrow.svg"
      width="24"
      height="24"
      alt=""
      draggable="false"
    />
  );
};

export const WhiteArrow = () => {
  return (
    <img
      src="/icons/white-arrow.svg"
      width="24"
      height="24"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowTopRightCirclePrimary = () => {
  return (
    <img
      src="/icons/arrow-top-right-circle-primary.svg"
      width="128"
      height="128"
      alt=""
      draggable="false"
    />
  );
};

export const Create = () => {
  return (
    <img
      src="/icons/creer.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const Share = () => {
  return (
    <img
      src="/icons/partager.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const Questionnaire = () => {
  return (
    <img
      src="/icons/questionnaire.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const Selection = () => {
  return (
    <img
      src="/icons/selection.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowRightBlack = () => {
  return (
    <img
      src="/icons/arrow-right-black.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ArrowRightPrimary = () => {
  return (
    <img
      src="/icons/arrow-right-primary.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const PlayIcon = ({ className = '' }) => {
  return (
    <img
      className={className}
      src="/icons/play-icon.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const PauseIcon = ({ className = '' }) => {
  return (
    <img
      className={className}
      src="/icons/pause-icon.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const PlusSign = () => {
  return (
    <img
      src="/icons/plus-sign.svg"
      width="40"
      height="40"
      alt=""
      draggable="false"
    />
  );
};

export const PlusSignBlack = () => {
  return (
    <img
      src="/icons/plus-sign-black.svg"
      width="40"
      height="40"
      alt=""
      draggable="false"
    />
  );
};

export const CreateWhite = () => {
  return (
    <img
      src="/icons/creer-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const ShareWhite = () => {
  return (
    <img
      src="/icons/partager-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const QuestionnaireWhite = () => {
  return (
    <img
      src="/icons/questionnaire-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const SelectionWhite = () => {
  return (
    <img
      src="/icons/selection-white.svg"
      width="32"
      height="32"
      alt=""
      draggable="false"
    />
  );
};

export const Formation = () => {
  return (
    <img
      src="/icons/formation.svg"
      width="60"
      height="60"
      alt=""
      draggable="false"
    />
  );
};

export const Ressources = () => {
  return (
    <img
      src="/icons/ressources.svg"
      width="60"
      height="60"
      alt=""
      draggable="false"
    />
  );
};

export const Community = () => {
  return (
    <img
      src="/icons/community.svg"
      width="60"
      height="60"
      alt=""
      draggable="false"
    />
  );
};

export const Crown = () => {
  return (
    <img
      src="/icons/crown.svg"
      width="60"
      height="60"
      alt=""
      draggable="false"
    />
  );
};

export const StarBlack = () => {
  return (
    <img
      src="/icons/star-black.svg"
      width="20"
      height="20"
      alt=""
      draggable="false"
    />
  );
};

export const StarPrimary = () => {
  return (
    <img
      className="level-up__blocks__item__icon"
      src="/icons/star-primary.svg"
      width="181"
      height="186"
      alt=""
      draggable="false"
    />
  );
};

export const SendIcon = () => {
  return (
    <img
      src="/icons/send.svg"
      width="75"
      height="56"
      alt=""
      draggable="false"
    />
  );
};

export const Check = () => {
  return (
    <img
      src="/icons/check.svg"
      width="15"
      height="10"
      alt=""
      draggable="false"
    />
  );
};

export const PrimaryCheck = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#FF5C57" />
      <path
        d="M10.546 17L6 12.2599L7.1365 11.0748L10.546 14.6299L17.8635 7L19 8.18503L10.546 17Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export const YellowCheck = () => {
  return (
    <img
      src="/icons/yellow-check.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};

export const BlackCheck = () => {
  return (
    <img
      src="/icons/black-check.svg"
      width="30"
      height="30"
      alt=""
      draggable="false"
    />
  );
};

export const GreyCross = () => {
  return (
    <img
      src="/icons/grey-cross.svg"
      width="20"
      height="20"
      alt=""
      draggable="false"
    />
  );
};

export const GreyDownArrow = () => {
  return (
    <img
      src="/icons/grey-down-arrow.svg"
      width="12"
      height="6"
      alt="grey down arrow"
      draggable="false"
    />
  );
};

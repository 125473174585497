'use client';

import classnames from 'classnames';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Link } from '../link';

import './index.css';

const CLOSE_SCROLL_THRESHOLD = 32;

export const MobileMenuClient = ({ storyFullSlug, locale, blok }) => {
  const lastScrollTop = useRef(0);
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    lastScrollTop.current = typeof window !== 'undefined' ? window.scrollY : 0;

    setOpen((state) => {
      return !state;
    });
  }, [setOpen]);

  const handleScroll = useCallback(() => {
    if (open) {
      const scrollTop = typeof window !== 'undefined' ? window.scrollY : 0;

      if (
        Math.abs(lastScrollTop.current - scrollTop) > CLOSE_SCROLL_THRESHOLD
      ) {
        setOpen(false);
      }
    }
  }, [open, setOpen]);

  useLayoutEffect(() => {
    if (typeof document !== 'undefined' && open) {
      document.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('scroll', handleScroll);
      }
    };
  }, [open]);

  return (
    <>
      {open ? (
        <button
          className="mobile-menu-overlay"
          onClick={toggle}
          aria-label="Close"
        />
      ) : null}

      <header
        className={classnames('mobile-menu', {
          'mobile-menu--open': open,
        })}
      >
        <button className="mobile-menu__button" onClick={toggle}>
          <span className="mobile-menu__button__text">Menu</span>

          <img
            src="/icons/cross-white.svg"
            width="14"
            height="14"
            alt=""
            className="mobile-menu__button__cross"
          />
        </button>

        <menu className="mobile-menu__links">
          <ul>
            {[...blok.content.links, ...(blok.content.cta || [])].map(
              (item) => {
                const items = [];

                const hasSubLinks = item.subLinks?.length > 0;

                if (
                  !hasSubLinks ||
                  (hasSubLinks &&
                    item.link?.cached_url &&
                    !item.subLinks.find((subLink) => {
                      return subLink.link?.cached_url === item.link?.cached_url;
                    }))
                ) {
                  items.push(item);
                }

                items.push(...(item.subLinks || []));

                return items.map((subItem) => {
                  return (
                    <li key={subItem._uid}>
                      <Link
                        link={subItem.link}
                        className={classnames('mobile-menu__link')}
                      >
                        {subItem.title}
                      </Link>
                    </li>
                  );
                });
              },
            )}
          </ul>
        </menu>
      </header>
    </>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';

import { ArrowRolledUp } from '@/components/icons';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';

export const QuestionsCards = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} className="questions-cards">
      <MaxWidth>
        <div className="questions-cards__content">
          {blok.items.map((item, index) => {
            return (
              <div
                className="questions-cards__card"
                key={item._uid}
                {...storyblokEditable(item)}
              >
                <div className="questions-cards__card__client__container">
                  <div className="questions-cards__card__client">
                    {item.clientPicture?.filename ? (
                      <Image
                        className="questions-cards__card__client__picture"
                        image={item.clientPicture}
                        sizes="53px"
                      />
                    ) : null}
                    <div className="questions-cards__card__client__infos">
                      <div className="questions-cards__card__client__infos__container">
                        {item.clientName ? (
                          <p
                            className="questions-cards__card__client__infos__name"
                            dangerouslySetInnerHTML={{
                              __html: markdownToHtml(item.clientName),
                            }}
                          />
                        ) : null}

                        {item.questionSent ? (
                          <p
                            className="questions-cards__card__client__question-sent"
                            dangerouslySetInnerHTML={{
                              __html: markdownToHtml(item.questionSent),
                            }}
                          />
                        ) : null}
                      </div>

                      {item.clientMemberSince ? (
                        <p
                          className="questions-cards__card__client__infos__member-since"
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(item.clientMemberSince),
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="questions-cards__card__content">
                    <p
                      className="questions-cards__card__content__question"
                      dangerouslySetInnerHTML={{
                        __html: markdownToHtml(item.question),
                      }}
                    />

                    <div className="questions-cards__card__content__actions">
                      <p>Like</p>
                      <p>Reply</p>
                    </div>
                  </div>
                </div>
                <div className="questions-cards__card__footer">
                  <div className="questions-cards__card__footer__background">
                    <Image
                      className="questions-cards__card__footer__background__image"
                      image={item.background}
                      sizes="550vw"
                    />
                  </div>
                  <div className="questions-cards__card__footer__container">
                    <ArrowRolledUp />

                    {item.image?.filename ? (
                      <div className="questions-cards__card__footer__image-container">
                        <Image
                          className="questions-cards__card__footer__image"
                          image={item.image}
                          sizes="100vw"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

import React from 'react';

export const ItemBackground = ({ id, first = false }) => {
  return first ? (
    <svg
      width="344"
      height="165"
      viewBox="0 0 344 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 8C41 3.58172 44.5817 0 49 0H281.676C284.277 0 286.715 1.26423 288.214 3.38967L340.749 77.8897C342.698 80.6541 342.698 84.3459 340.749 87.1103L288.214 161.61C286.715 163.736 284.277 165 281.676 165H49C44.5817 165 41 161.418 41 157V8Z"
        fill={`url(#paint0_linear_4241_1921_${id})`}
      />
      <path
        d="M49 0.5H281.676C284.115 0.5 286.401 1.68522 287.806 3.67782L340.34 78.1778C342.168 80.7695 342.168 84.2305 340.34 86.8222L287.806 161.322C286.401 163.315 284.115 164.5 281.676 164.5H49C44.8579 164.5 41.5 161.142 41.5 157V8C41.5 3.85787 44.8579 0.5 49 0.5Z"
        stroke={`url(#paint1_linear_4241_1921_${id})`}
        strokeOpacity="0.5"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_4241_1921_${id}`}
          x1="171.029"
          y1="165"
          x2="171.029"
          y2="16.7308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--color)" stopOpacity="0.2" />
          <stop offset="1" stopColor="var(--color)" stopOpacity="0.04" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_4241_1921_${id}`}
          x1="106.398"
          y1="-114.93"
          x2="319.644"
          y2="-12.8031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.5" stopColor="white" stopOpacity="0.52" />
          <stop offset="1" stopColor="white" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="344"
      height="165"
      viewBox="0 0 344 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_4241_1922_${id})`}>
        <path
          d="M8.60484 12.5291C4.95913 7.22074 8.75967 0 15.1993 0H284.005C286.662 0 289.146 1.31934 290.634 3.52103L340.974 78.021C342.802 80.7271 342.802 84.2729 340.974 86.979L290.634 161.479C289.146 163.681 286.662 165 284.005 165H15.1993C8.75966 165 4.95912 157.779 8.60483 152.471L53.5497 87.0291C55.4236 84.3005 55.4236 80.6995 53.5497 77.9709L8.60484 12.5291Z"
          fill={`url(#paint0_linear_4241_1922_${id})`}
        />
        <path
          d="M15.1993 0.5H284.005C286.496 0.5 288.825 1.73689 290.22 3.80097L340.559 78.301C342.273 80.8379 342.273 84.1621 340.559 86.699L290.22 161.199C288.825 163.263 286.496 164.5 284.005 164.5H15.1993C9.16214 164.5 5.59913 157.731 9.01698 152.754L53.9618 87.3121C55.9529 84.413 55.9529 80.587 53.9618 77.6879L9.01699 12.246C5.59914 7.26944 9.16215 0.5 15.1993 0.5Z"
          stroke={`url(#paint1_linear_4241_1922_${id})`}
          strokeOpacity="0.5"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_4241_1922_${id}`}
          x1="14.966"
          y1="115.375"
          x2="333.852"
          y2="148.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--color)" stopOpacity="0.2" />
          <stop offset="1" stopColor="var(--color)" stopOpacity="0.04" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_4241_1922_${id}`}
          x1="62.6654"
          y1="-114.93"
          x2="270.169"
          y2="-19.7055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.5" stopColor="white" stopOpacity="0.52" />
          <stop offset="1" stopColor="white" stopOpacity="0.3" />
        </linearGradient>
        <clipPath id={`clip0_4241_1922_${id}`}>
          <rect width="344" height="165" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

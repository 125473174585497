'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

import { Button } from '@/components/button';
import * as Icons from '@/components/icons';
import { Image } from '@/components/image';
import { Link } from '@/components/link';

import { isLink } from '@/helpers/storyblok/is-link';
import { render } from '@/helpers/storyblok/rich-text';
import { formatCurrency } from '@/helpers/text';

import './item.css';

export const ModuleListClientItem = ({
  blok,
  headingLevel,
  useSlider = false,
}) => {
  const TitleEl = headingLevel;

  const link = blok.cta?.[0]?.link;

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames(
        'module-list__item',
        `module-list__item--theme-${blok.theme || 'normal'}`,
        `module-list__item--shadow-${blok.shadow || 'black'}`,
        {
          'module-list__item--not-published': !blok.published,
          'keen-slider__slide': useSlider,
          'module-list__item--disabled': blok.disabled,
          'module-list__item--remove-from-mobile-slider':
            blok.removeFromMobileSlider,
        },
      )}
    >
      <div className="module-list__item__plus-sign">
        <Icons.PlusSignBlack />
      </div>

      <div className="module-list__item__image">
        <Image image={blok.image} sizes="(max-width: 768px) 700px, 500px" />
      </div>

      <div className="module-list__item__content">
        <div className="module-list__item__content__wrap">
          <div className="module-list__item__program">{blok.program}</div>

          {blok.title ? (
            <TitleEl className="module-list__item__content__wrap__title">
              {isLink(link) ? (
                <Link link={link}>{blok.title}</Link>
              ) : (
                blok.title
              )}
            </TitleEl>
          ) : null}

          {blok.description && render(blok.description) ? (
            <div className="module-list__item__content__wrap__description">
              {render(blok.description)}
            </div>
          ) : null}
        </div>

        <div className="module-list__item__content__wrap__bottom">
          {blok.price || blok.priceTag ? (
            <div className="module-list__item__content__wrap__price-block">
              {blok.price ? (
                <p className="module-list__item__content__wrap__price">
                  {formatCurrency(blok.price)}
                  <sub> HT</sub>
                </p>
              ) : null}
              {blok.priceTag ? (
                <p className="module-list__item__content__wrap__price-tag">
                  {blok.priceTag}
                </p>
              ) : null}
            </div>
          ) : null}

          <div className="module-list__item__content__button">
            {blok.cta.map((item) => {
              return (
                <Button
                  {...storyblokEditable(item)}
                  key={item._uid}
                  theme={item.theme}
                  as="p"
                >
                  {item.title}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

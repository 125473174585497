'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React from 'react';

import { Button } from '@/components/button';
import * as ICONS from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { markdownToHtml } from '@/helpers/string';

import './index.css';

import { hasText, render } from '@/helpers/storyblok/rich-text';

export const OffersCardsClient = ({ locale, storyFullSlug, blok }) => {
  return (
    <div {...storyblokEditable(blok)} className="offers-cards">
      <MaxWidth>
        <div className="offers-cards__content">
          {blok.offersCardsItem.map((item) => {
            return (
              <div
                className={classNames(
                  `offers-cards__card offers-cards__card--${item.themeCard}`,
                  {
                    [`offers-cards__card--${item.themeCard}--is-featured`]:
                      item.featured,
                  },
                )}
                key={item._uid}
                {...storyblokEditable(item)}
              >
                {item.featured ? (
                  <p className="offers-cards__card__featured">Plus populaire</p>
                ) : null}

                {item.icon ? (
                  <div className="offers-cards__card__icon">
                    {item.icon === 'diamond'
                      ? '💎'
                      : item.icon === 'rocket'
                        ? '🚀'
                        : item.icon === 'team'
                          ? '👥'
                          : null}
                  </div>
                ) : null}

                <h3 className="offers-cards__card__title">{item.title}</h3>

                {item.infoTitle ? (
                  <p className="offers-cards__card__title-description">
                    {item.titleDescription}
                  </p>
                ) : null}

                {item.price ? (
                  <p
                    className="offers-cards__card__price"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.price),
                    }}
                  />
                ) : null}

                {hasText(item.teaser) ? (
                  <div className="offers-cards__card__teaser">
                    {render(item.teaser)}
                  </div>
                ) : null}
                {item.cta?.length > 0 ? (
                  <div className="offers-cards__card__button__cta">
                    {(item.cta || []).map((cta) => {
                      return (
                        <Button
                          key={cta._uid}
                          link={cta.link}
                          theme={item.themeCard}
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(cta.title),
                          }}
                        />
                      );
                    })}
                  </div>
                ) : null}

                {item.offerDescription ? (
                  <p
                    className="offers-cards__card__offer-description"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.offerDescription),
                    }}
                  />
                ) : null}
                {item.infoTitle ? (
                  <p
                    className="offers-cards__card__info-title"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.infoTitle),
                    }}
                  ></p>
                ) : null}

                {item.offersItems?.length > 0 ? (
                  <div className="offers-cards__card__offers">
                    {item.offersItems.map((offer) => {
                      return (
                        <div
                          className="offers-cards__card__offer"
                          key={offer._uid}
                        >
                          <div className="offers-cards__card__offer-green__icon">
                            <ICONS.GreenCheck />
                          </div>

                          <p className="offers-cards__card__offer-text">
                            {offer.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import React from 'react';

import { CountdownForm } from '@/components/countdown-form';
import { MaxWidth } from '@/components/max-width';

import './index.css';

export const CountdownFooterClient = ({ storyFullSlug, locale, blok }) => {
  const TitleEl = blok.level || 'h2';

  return (
    <div {...storyblokEditable(blok)} className="countdown-footer">
      <MaxWidth>
        {blok.title ? (
          <TitleEl className="countdown-footer__title">{blok.title}</TitleEl>
        ) : null}

        <div className="countdown-footer__form">
          <CountdownForm
            anchor={blok.anchor}
            date={new Date(blok.countdownDate)}
            subtitle={blok.countdownSubtitle}
            form={blok.form?.[0]}
          />
        </div>
      </MaxWidth>
    </div>
  );
};

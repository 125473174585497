'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { render, richTextToString } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import { ItemBackground } from './background';

import './index.css';

const Chevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.52984 5.46952L3.52983 5.46951C3.38912 5.3288 3.19829 5.24976 2.9993 5.24976C2.80032 5.24976 2.60948 5.3288 2.46877 5.46951C2.32807 5.61021 2.24902 5.80105 2.24902 6.00003C2.24902 6.19902 2.32807 6.38986 2.46877 6.53056L7.46868 11.5305C7.46871 11.5305 7.46874 11.5305 7.46877 11.5306C7.53841 11.6002 7.62109 11.6555 7.7121 11.6933C7.80315 11.731 7.90074 11.7504 7.9993 11.7504C8.09786 11.7504 8.19546 11.731 8.28651 11.6933C8.37751 11.6555 8.46019 11.6002 8.52983 11.5306C8.52986 11.5305 8.52989 11.5305 8.52993 11.5305L13.5298 6.53056C13.5995 6.46089 13.6548 6.37818 13.6925 6.28715C13.7302 6.19613 13.7496 6.09856 13.7496 6.00003C13.7496 5.90151 13.7302 5.80394 13.6925 5.71291C13.6548 5.62189 13.5995 5.53918 13.5298 5.46951C13.4602 5.39984 13.3774 5.34457 13.2864 5.30687C13.1954 5.26916 13.0978 5.24976 12.9993 5.24976C12.9008 5.24976 12.8032 5.26916 12.7122 5.30687C12.6212 5.34457 12.5384 5.39984 12.4688 5.46951L12.4688 5.46952L7.9993 9.93958L3.52984 5.46952Z"
        fill="var(--color)"
        stroke="var(--color)"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const LandingProgramClient = ({ storyFullSlug, locale, blok }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const lockTimeout = useRef(null);

  const handleScroll = () => {
    if (!lockTimeout.current) {
      setOpenIndex(null);
    }
  };

  const toggle = useCallback(
    (index) => {
      if (openIndex === index) {
        setOpenIndex(null);
      } else {
        setOpenIndex(index);

        if (lockTimeout.current) {
          clearTimeout(lockTimeout.current);
        }

        lockTimeout.current = setTimeout(() => {
          lockTimeout.current = null;
        }, 800);
      }
    },
    [openIndex],
  );

  return (
    <div {...storyblokEditable(blok)} className="landing-program">
      <MaxWidth>
        <div className="landing-program__desktop">
          <Image
            sizes="(max-width: 768px) 0px, 1200px"
            image={blok.desktopImage}
          />
        </div>

        <div className="landing-program__mobile" onScroll={handleScroll}>
          {(blok.mobileItems || []).map((item, index) => {
            const active = openIndex === index;
            const unactive = openIndex !== null && openIndex !== index;

            return (
              <div
                {...storyblokEditable(item)}
                key={item._uid}
                className={classNames(
                  'landing-program__item',
                  `landing-program__item--theme-${item.theme || 'white'}`,
                  {
                    'landing-program__item--active': active,
                    'landing-program__item--unactive': unactive,
                  },
                )}
              >
                <div className="landing-program__item__background">
                  <ItemBackground id={item._uid} first={index === 0} />

                  <div className="landing-program__item__background__plain" />
                </div>

                {item.date ? (
                  <p className="landing-program__item__date">{item.date}</p>
                ) : null}

                {item.title ? (
                  <h3
                    className="landing-program__item__title"
                    dangerouslySetInnerHTML={{
                      __html: markdownToHtml(item.title),
                    }}
                  />
                ) : null}

                {richTextToString(item.content) ? (
                  <>
                    <button
                      className="landing-program__item__toggle"
                      type="button"
                      onClick={() => {
                        toggle(index);
                      }}
                    >
                      {active
                        ? item.mobileDetailsHide || blok.mobileDetailsHide
                        : item.mobileDetailsShow || blok.mobileDetailsShow}

                      <Chevron />
                    </button>

                    <AnimateHeight
                      className="landing-program__item__content"
                      duration={300}
                      height={active ? 'auto' : 0}
                    >
                      {render(item.content)}
                    </AnimateHeight>
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

import serverContext from 'server-only-context';

import { detectLocale } from '@/helpers/storyblok';

import { DEFAULT_LOCALE } from '@/config';

export const [getLocale, setLocale] = serverContext(DEFAULT_LOCALE);

export const [getStory, _setStory] = serverContext(null);

export const setStory = (story) => {
  _setStory(story);
  setLocale(detectLocale((story?.full_slug || '/').split('/')));
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import { useKeenSlider } from 'keen-slider/react';

import { Image } from '@/components/image';

import 'keen-slider/keen-slider.min.css';
import './index.css';
import './item.css';

import { markdownToHtml } from '@/helpers/string';

export const SliderTeam = ({ blok }) => {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        origin: 'center',
        perView: 'auto',
        spacing: 32,
      },
      breakpoints: {
        '(max-width: 768px)': {
          slides: {
            origin: 'center',
            perView: 'auto',
            spacing: 16,
          },
        },
      },
      mode: 'snap',
    },
    [],
  );

  return (
    <div className="slider-team" {...storyblokEditable(blok)}>
      <div className="slider-team__max-width">
        <div ref={sliderRef} className="keen-slider slider-team-content">
          {blok.teamCardItem.map((item) => {
            return (
              <div
                key={item._uid}
                className="keen-slider__slide slider-team__item"
                {...storyblokEditable(item)}
              >
                <Image
                  className="slider-team__item__picture"
                  image={item.image}
                  sizes="300px"
                />

                <div className="slider-team__item__content">
                  <div className="slider-team__item__text-content">
                    <p className="slider-team__item__text__title">
                      {item.title}
                    </p>

                    <div
                      className="slider-team__item__text__role"
                      dangerouslySetInnerHTML={{
                        __html: markdownToHtml(item.text),
                      }}
                    />
                  </div>

                  <Image
                    className="slider-team__item__logo"
                    image={item.logo}
                    sizes="60px"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

'use client';

import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';
import { useRef, useState } from 'react';

import { Button } from '@/components/button';
import { PrimaryCheck } from '@/components/icons';

import { render } from '@/helpers/storyblok/rich-text';

import './form.css';

const STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const Form = ({ blok }) => {
  const form = blok?.form[0];

  const formRef = useRef(null);

  const [state, setState] = useState(STATE.IDLE);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState(STATE.LOADING);

    const data = {
      site: 'Unlock-M',
      form: form.id,
      firstName,
      lastName,
      company,
      email,
      message,
    };

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch('/api/form', options);
      if (!response.ok) {
        throw new Error(`${form.id} form submit error`);
      }
      setState(STATE.SUCCESS);
      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureMessage('Form submitted successfully');
      });
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtras(data);
        Sentry.captureException(error);
      });
      setState(STATE.ERROR);
    }
  };

  const getSplitClass = (fields) => {
    const fieldCount = Object.values(fields).reduce(
      (count, field) => (field ? count + 1 : count),
      0,
    );
    return classnames('contact-form__form__split', {
      'contact-form__form__split--flex': fieldCount === 1,
    });
  };

  return (
    <>
      <div
        className={classnames('contact-form__form', {
          'contact-form__form--state-loading': state === STATE.LOADING,
          'contact-form__form--state-success': state === STATE.SUCCESS,
          'contact-form__form--state-error': state === STATE.ERROR,
        })}
      >
        {form.title ? (
          <div className="contact-form__form__title">{render(form.title)}</div>
        ) : null}
        <form onSubmit={handleSubmit} ref={formRef}>
          {form.lastNameLabel || form.firstNameLabel ? (
            <div
              className={getSplitClass({
                lastName: form.lastNameLabel,
                firstName: form.firstNameLabel,
              })}
            >
              {form.lastNameLabel ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="lastName"
                    placeholder={form.lastNameLabel}
                    autoComplete="lastName"
                    value={lastName}
                    onInput={(event) => setLastName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.firstNameLabel ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="firstName"
                    placeholder={form.firstNameLabel}
                    autoComplete="firstName"
                    value={firstName}
                    onInput={(event) => setFirstName(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {form.emailLabel || form.companyLabel ? (
            <div
              className={getSplitClass({
                name: form.emailLabel,
                company: form.companyLabel,
              })}
            >
              {form.emailLabel ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="email"
                    name="email"
                    placeholder={form.emailLabel}
                    autoComplete="email"
                    value={email}
                    onInput={(event) => setEmail(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
              {form.companyLabel ? (
                <div className="contact-form__form__input-container">
                  <input
                    className="contact-form__form__input"
                    type="text"
                    name="company"
                    placeholder={form.companyLabel}
                    autoComplete="company"
                    value={company}
                    onInput={(event) => setCompany(event.target.value)}
                    readOnly={state === STATE.LOADING}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {form.messageLabel ? (
            <div className="contact-form__form__input-container-textarea">
              <textarea
                className="contact-form__form__textarea"
                name="message"
                placeholder={form.messageLabel}
                autoComplete="no"
                value={message}
                onInput={(event) => setMessage(event.target.value)}
                readOnly={state === STATE.LOADING}
              />
            </div>
          ) : null}

          {state === STATE.ERROR ? (
            <div className="contact-form__form__error">
              {render(form.errorMessage)}
            </div>
          ) : null}

          <div className="contact-form__form__cta">
            <Button
              theme="primary"
              disabled={
                [STATE.SUCCESS].includes(state) ||
                message === '' ||
                email === '' ||
                EmailValidator.validate(email) === false
              }
              loading={[STATE.LOADING].includes(state)}
            >
              {form.submitLabel}
            </Button>
          </div>
        </form>
        {state === STATE.SUCCESS ? (
          <div className="contact-form__form__success">
            <div className="contact-form__form__success__icon">
              <PrimaryCheck />
            </div>
            {form.successMessage ? (
              <div className="contact-form__form__success__message">
                {render(form.successMessage)}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PauseIcon, PlayIcon } from '@/components/icons';
import { Image } from '@/components/image';

import { ASSET_TYPE, getAssetType } from '@/helpers/image';

export const HeroFormationSlider = ({ assets }) => {
  /**
   * When any of the video is playing with sound, disable slide change on galery
   * items mouse hover
   */
  const [oneVideoHasStarted, setOneVideoHasStarted] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const handleSlide = useCallback(
    (index) => {
      setCurrentIndex(index);
      setOneVideoHasStarted(false);
    },
    [setCurrentIndex, setOneVideoHasStarted],
  );

  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto',
        spacing: 15,
      },
      mode: 'snap',
      created() {
        setLoaded(true);
      },
    },
    [],
  );

  return (
    <div
      className={classNames('hero-formation__slider', {
        'hero-formation__slider--loaded': loaded,
      })}
    >
      <div className="hero-formation__slider__cards">
        {assets?.map((item, index) => {
          return (
            <HeroFormationSliderAsset
              key={item._uid}
              item={item}
              currentIndex={currentIndex}
              index={index}
              setOneVideoHasStarted={setOneVideoHasStarted}
            />
          );
        })}
      </div>

      {assets.length > 1 ? (
        <div
          ref={sliderRef}
          className="hero-formation__slider__preview-cards"
          style={{ '--nb-items': assets.length }}
        >
          <ul className="hero-formation__slider__preview-cards__wrap keen-slider">
            {assets?.map((item, index) => {
              const assetType = getAssetType(item.asset.filename);
              const isVideo = assetType === ASSET_TYPE.VIDEO;

              return (
                <li key={item._uid}>
                  <button
                    className={classNames(
                      'hero-formation__slider__preview-card',
                      'keen-slider__slide',
                      `hero-formation__slider__preview-card--is-${assetType}`,
                      {
                        'hero-formation__slider__preview-card--current':
                          index == currentIndex,
                      },
                    )}
                    onClick={() => handleSlide(index)}
                    onMouseEnter={
                      oneVideoHasStarted ? null : () => handleSlide(index)
                    }
                  >
                    {isVideo ? <PlayIcon /> : null}

                    <Image
                      image={isVideo ? item.previewImage : item.asset}
                      sizes="100px"
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export const HeroFormationSliderAsset = ({
  item,
  index,
  currentIndex,
  setOneVideoHasStarted,
}) => {
  const videoRef = useRef(null);

  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoHasPlayed, setVideoHasPlayed] = useState(false);
  const [videoHasPlayedPreview, setVideoHasPlayedPreview] = useState(true);

  const toggleVideo = () => {
    setVideoIsPlaying((current) => !current);
  };

  useEffect(() => {
    if (videoRef.current) {
      if (videoIsPlaying) {
        videoRef.current.muted = false;
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }

      if (!videoHasPlayed) {
        videoRef.current.muted = false;
        videoRef.current.currentTime = 0;
        setVideoHasPlayed(true);
        setOneVideoHasStarted(true);
      }
    }
  }, [videoIsPlaying]);

  useEffect(() => {
    setVideoIsPlaying(false);
    setVideoHasPlayed(false);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.muted = true;

      if (index === currentIndex) {
        videoRef.current.play();
        setVideoHasPlayedPreview(true);
      }
    }
  }, [currentIndex]);

  const assetType = getAssetType(item.asset.filename);

  let content;

  switch (assetType) {
    case ASSET_TYPE.IMAGE:
      content = (
        <Image image={item.asset} sizes="(max-width: 768px) 90vw, 50vw" />
      );

      break;

    case ASSET_TYPE.VIDEO:
      const videoUrl = item.asset.filename;
      const isFirstPlaying = !videoHasPlayed;

      content = (
        <>
          <button
            onClick={toggleVideo}
            className="hero-formation__slider__card__video-overlay"
          >
            <PauseIcon
              className={classNames(
                'hero-formation__slider__card__video-pause-icon',
                {
                  'hero-formation__slider__card__video-pause-icon--show':
                    !videoIsPlaying && !isFirstPlaying,
                },
              )}
            />
            <PlayIcon
              className={classNames(
                'hero-formation__slider__card__video-play-icon',
                {
                  'hero-formation__slider__card__video-play-icon--show':
                    !videoIsPlaying && isFirstPlaying,
                },
              )}
            />
          </button>

          {item.previewImage?.filename ? (
            <Image
              className={classNames(
                'hero-formation__slider__card__video-preview',
                {
                  'hero-formation__slider__card__video-preview--show':
                    !videoIsPlaying && isFirstPlaying && !videoHasPlayedPreview,
                },
              )}
              image={item.previewImage}
              sizes="(max-width: 768px) 90vw, 50vw"
            />
          ) : null}

          {videoUrl ? (
            <video
              ref={videoRef}
              src={videoUrl}
              autoPlay
              loop
              muted
              playsInline
            />
          ) : null}
        </>
      );

      break;
  }

  return content ? (
    <div
      key={item._uid}
      className={classNames('hero-formation__slider__card', {
        'hero-formation__slider__card--current': index == currentIndex,
      })}
    >
      {content}
    </div>
  ) : null;
};

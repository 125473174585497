import { DEFAULT_LOCALE, LOCALES } from '@/config';

/**
 * @typedef {'draft'|'published'} StoryblokVersion
 */

/**
 * @enum {StoryblokVersion}
 * @type {Object.<string, StoryblokVersion>}
 */
export const STORYBLOK_VERSION = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const RESOLVE_RELATIONS = ['shared-sections-import.sharedSection'];

export const detectLocale = (slug) => {
  return slug?.length > 0
    ? LOCALES.find((locale) => {
        return locale.shortCode === slug[0];
      }) || DEFAULT_LOCALE
    : DEFAULT_LOCALE;
};

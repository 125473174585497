'use client';

import { storyblokEditable } from '@storyblok/js';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Button } from '@/components/button';
import * as Icons from '@/components/icons';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import 'keen-slider/keen-slider.min.css';
import './index.css';

import classNames from 'classnames';

const ExpertsItem = ({ item, TitleEl }) => {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen((state) => {
      return !state;
    });
  }, [setOpen]);

  return (
    <div
      {...storyblokEditable(item)}
      className="experts__item keen-slider__slide"
    >
      <Image
        className="experts__item__image"
        image={item.image}
        sizes="450px"
      />

      <div className="experts__item__content">
        <TitleEl
          className="experts__item__title"
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(item.title),
          }}
        />

        {item.description ? (
          <AnimateHeight
            className={classNames('experts__item__description', {
              'experts__item__description--open': open,
            })}
            duration={300}
            height={open ? 'auto' : 48}
            style={{
              minHeight: 48,
            }}
          >
            {render(item.description)}
          </AnimateHeight>
        ) : null}

        {item.cta?.length > 0 ? (
          <div
            className={classNames('experts__item__cta', {
              'experts__item__cta--open': open,
            })}
          >
            {item.cta.map((link) => (
              <Button
                as="button"
                key={link._uid}
                {...storyblokEditable(link)}
                theme={link.theme}
                rightIcon={<Icons.DownArrow />}
                onClick={toggle}
              >
                {link.title}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const Experts = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h3';
  const [animated, setAnimated] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 'auto',
      spacing: 32,
    },
    breakpoints: {
      '(max-width: 650px)': {
        slides: {
          perView: 'auto',
          spacing: 16,
        },
      },
    },
    mode: 'snap',
    created(s) {
      setCurrentSlide(s.track.details.rel);
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
    animationStarted() {
      setAnimated(true);
    },
    animationStopped() {
      setAnimated(false);
    },
    animationEnded() {
      setAnimated(false);
    },
  });

  const handlePrevious = useCallback(
    (event) => {
      event.stopPropagation();
      if (!animated) {
        instanceRef.current?.prev();
      }
    },
    [animated, instanceRef],
  );

  const handleNext = useCallback(
    (event) => {
      event.stopPropagation();
      if (!animated) {
        instanceRef.current?.next();
      }
    },
    [animated, instanceRef],
  );

  return (
    <div {...storyblokEditable(blok)} className="experts">
      {currentSlide > 0 ? (
        <button
          className="experts__button experts__button--prev"
          onClick={handlePrevious}
          aria-label="previous slide"
        >
          <Icons.ArrowLeftCircleWhite />
        </button>
      ) : null}

      <button
        className="experts__button experts__button--next"
        onClick={handleNext}
        aria-label="next slide"
      >
        <Icons.ArrowRightCircleWhite />
      </button>

      <MaxWidth>
        <div className="experts__wrap keen-slider" ref={sliderRef}>
          {blok.items.map((item) => {
            return (
              <ExpertsItem key={item._uid} item={item} TitleEl={TitleEl} />
            );
          })}
        </div>
      </MaxWidth>
    </div>
  );
};

'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useState } from 'react';

import * as Icons from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { ModuleListClientItem } from './item';

import './index.css';

export const ModuleListClient = ({ blok, index }) => {
  const [animated, setAnimated] = useState(false);

  const headingLevel = blok.headingLevel || 'h3';

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 3,
        spacing: 24,
      },
      breakpoints: {
        '(max-width: 1024px)': {
          slides: {
            perView: 2.5,
            spacing: 24,
          },
        },
        '(max-width: 768px)': {
          slides: {
            perView: 'auto',
            spacing: 16,
          },
        },
      },
      mode: 'snap',

      animationStarted() {
        setAnimated(true);
      },
      animationStopped() {
        setAnimated(false);
      },
      animationEnded() {
        setAnimated(false);
      },
    },
    [],
  );

  const handlePrevious = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.prev?.();
      }
    },
    [animated, instanceRef],
  );

  const handleNext = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.next?.();
      }
    },
    [animated, instanceRef],
  );

  const firstOutMobileSliderCard =
    blok.items?.[0] && blok.items?.[0]?.removeFromMobileSlider
      ? blok.items?.[0]
      : null;

  const cardsOutSlider = blok.items.filter((item) => {
    return (
      item.removeFromMobileSlider &&
      ((firstOutMobileSliderCard && firstOutMobileSliderCard != item) ||
        !firstOutMobileSliderCard)
    );
  });

  return (
    <div
      className={classNames('module-list', {
        'module-list--first': index === 0,
        'module-list--slider': blok.useSlider,
        'module-list--not-slider': !blok.useSlider,
      })}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        {blok.useSlider ? (
          <button
            className="module-list__button module-list__button--prev"
            onClick={handlePrevious}
            aria-label="previous slide"
          >
            <Icons.ArrowLeftCircle />
          </button>
        ) : null}

        {/* First mobile card (out of slider) */}
        {firstOutMobileSliderCard && !blok.useSlider ? (
          <div className="module-list__mobile-first-card">
            <ModuleListClientItem
              blok={firstOutMobileSliderCard}
              headingLevel={headingLevel}
            />
          </div>
        ) : null}

        {/* Cards slider or grid */}
        <div
          className={classNames('module-list__cards-container', {
            'keen-slider': blok.useSlider,
          })}
          ref={blok.useSlider ? sliderRef : null}
        >
          {blok.items.map((item) => {
            return (
              <ModuleListClientItem
                key={item._uid}
                blok={item}
                headingLevel={headingLevel}
                useSlider={blok.useSlider}
              />
            );
          })}
        </div>

        {/* Cards after in mobile (out of slider) */}
        {cardsOutSlider.length > 0 && !blok.useSlider ? (
          <div className="module-list__cards-mobile-last-cards">
            {cardsOutSlider.map((item) => {
              return (
                <ModuleListClientItem
                  key={item._uid}
                  blok={item}
                  headingLevel={headingLevel}
                />
              );
            })}
          </div>
        ) : null}

        {blok.useSlider ? (
          <button
            className="module-list__button module-list__button--next"
            onClick={handleNext}
            aria-label="next slide"
          >
            <Icons.ArrowRightCircle />
          </button>
        ) : null}

        {/* {loaded && instanceRef.current && blok.useSlider && (
          <div className="module-list__dots">
            {[...Array(Math.floor(blok.items?.length / perPage)).keys()].map(
              (idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx * perPage);
                    }}
                    className={
                      'module-list__dot' +
                      (Math.floor(currentSlide / perPage) === idx
                        ? ' active'
                        : '')
                    }
                  ></button>
                );
              },
            )}
          </div>
        )} */}
      </MaxWidth>
    </div>
  );
};

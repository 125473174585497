'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { useCallback, useState } from 'react';

import * as Icons from '@/components/icons';
import { Image } from '@/components/image';

import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

export const TutorsClient = ({ storyFullSlug, locale, blok }) => {
  const [animated, setAnimated] = useState(false);

  /* keenslider */
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto',
        spacing: 32,
      },
      breakpoints: {
        '(max-width: 768px)': {
          slides: {
            perView: 'auto',
            spacing: 16,
            origin: 'center',
          },
        },
      },

      mode: 'snap',

      animationStarted() {
        setAnimated(true);
      },
      animationStopped() {
        setAnimated(false);
      },
      animationEnded() {
        setAnimated(false);
      },
    },
    [],
  );

  const handlePrevious = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.prev?.();
      }
    },
    [animated, instanceRef],
  );

  const handleNext = useCallback(
    (event) => {
      event.stopPropagation();

      if (!animated) {
        instanceRef.current?.next?.();
      }
    },
    [animated, instanceRef],
  );

  return (
    <div {...storyblokEditable(blok)} className="tutors">
      <div className="tutors__wrap">
        <button
          className="tutors__button tutors__button__black tutors__button--prev"
          onClick={handlePrevious}
          aria-label="previous slide"
        >
          <Icons.ArrowLeftCircle />
        </button>

        <button
          className="tutors__button tutors__button__white tutors__button--prev"
          onClick={handlePrevious}
          aria-label="previous slide"
        >
          <Icons.ArrowLeftCircleWhite />
        </button>

        {blok.items?.length > 0 ? (
          <div className="tutors__list keen-slider" ref={sliderRef}>
            {blok.items?.map((item, index) => {
              return (
                <div
                  key={item._uid}
                  className="tutors__item keen-slider__slide"
                >
                  <div
                    key={item._uid}
                    className={classNames('tutors__item-box', {
                      single: index === blok.items.length - 1,
                    })}
                  >
                    <div className="tutors__item__image">
                      <Image sizes="122px" image={item.image} />
                    </div>
                    <h2
                      className="tutors__item__title"
                      dangerouslySetInnerHTML={{
                        __html: markdownToHtml(item.title),
                      }}
                    />
                    <div className="tutors__item__content">
                      <hr />
                      {render(item.content)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        <button
          className="tutors__button tutors__button__black tutors__button--next"
          onClick={handleNext}
          aria-label="next slide"
        >
          <Icons.ArrowRightCircle />
        </button>

        <button
          className="tutors__button tutors__button__white tutors__button--next"
          onClick={handleNext}
          aria-label="next slide"
        >
          <Icons.ArrowRightCircleWhite />
        </button>
      </div>
    </div>
  );
};

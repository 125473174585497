'use client';

import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { useRef, useState } from 'react';

import { Button } from '@/components/button';
import { Image } from '@/components/image';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';
import { markdownToHtml } from '@/helpers/string';

import './index.css';

import { InlineForm } from '@/components/inline-form';

export const ImageText = ({ blok }) => {
  const TitleEl = blok.titleLevel || 'h2';

  const form = blok.form?.[0];

  const videoRef = useRef(null);
  const [videoStarted, setVideoStarted] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(true);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (!videoStarted) {
        videoRef.current.currentTime = 0;
        videoRef.current.muted = false;
        videoRef.current.play();

        setVideoStarted(true);
        setVideoPlaying(true);
      } else if (videoPlaying) {
        videoRef.current.pause();

        setVideoPlaying(false);
      } else {
        videoRef.current.muted = false;
        videoRef.current.play();

        setVideoPlaying(true);
      }
    }
  };

  return (
    <div {...storyblokEditable(blok)} className="image-text">
      <MaxWidth>
        <div
          className={classNames('image-text__wrap', {
            'image-text__wrap--reverse': blok.reverse,
          })}
        >
          <div className="image-text__wrap__text">
            <TitleEl
              className="image-text__wrap__text__title"
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(blok.title),
              }}
            />

            {blok.horizontalLine ? <hr /> : null}

            <div className="image-text__wrap__text__content">
              {render(blok.text)}
            </div>

            {form ? (
              <div className="image-text__wrap__text__form">
                <InlineForm {...form} />
              </div>
            ) : null}

            {blok.cta?.length > 0 ? (
              <div className="image-text__wrap__text__cta">
                {blok.cta.map((item) => {
                  return (
                    <Button
                      key={item._uid}
                      link={item.link}
                      theme="primary"
                      dangerouslySetInnerHTML={{
                        __html: markdownToHtml(item.title),
                      }}
                      {...storyblokEditable(item)}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>

          {blok.video?.filename ? (
            <div
              className={classNames('image-text__wrap__video', {
                'image-text__wrap__video--started': videoStarted,
                'image-text__wrap__video--playing': videoPlaying,
              })}
            >
              <div className="image-text__wrap__video__wrap">
                <video
                  ref={videoRef}
                  src={blok.video?.filename}
                  muted
                  playsInline
                  preload="auto"
                  disablePictureInPicture
                  loop
                  autoPlay
                />

                <button
                  className="image-text__wrap__video__play"
                  onClick={handlePlayPause}
                >
                  <img src="/icons/play.svg" width="64" height="64" alt="" />
                </button>
              </div>
            </div>
          ) : blok.imageItem?.length > 0 ? (
            <div
              className={classNames('image-text__wrap__image', {
                'image-text__wrap__image--grid': blok.imageItem.length > 2,
                'image-text__wrap__image--single': blok.imageItem.length === 1,
                'image-text__wrap__image--duo': blok.imageItem.length === 2,
              })}
            >
              {blok.imageItem?.map((item) => {
                return (
                  <Image
                    key={item._uid}
                    image={item.image}
                    sizes={item.sizes}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

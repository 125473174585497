import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

export const useId = (initial = null) => {
  const [id, setId] = useState(initial);

  useEffect(() => {
    setId(initial || `id_${nanoid()}`);
  }, []);

  return id;
};

'use client';

import { useEffect, useRef } from 'react';

/**
 * Check if rect is in viewport
 * @param {number} rect.top
 * @returns boolean
 */
const elementIsInViewport = (element) => {
  const rect = element.getBoundingClientRect();

  const top = rect.top - window.innerHeight;

  return top < 0 && rect.y + rect.height > 0;
};

export const VideoAnimation = ({ src, loop = false, poster }) => {
  const videoRef = useRef(null);
  const canPlay = useRef(true);

  useEffect(() => {
    const update = () => {
      if (videoRef.current) {
        videoRef.current.setAttribute('preload', '1');

        const play = () => {
          try {
            videoRef.current.setAttribute('muted', '1');

            const playPromise = videoRef.current.play?.();
            if (playPromise !== undefined) {
              playPromise.catch(() => {});
            }

            canPlay.current = false;
          } catch (error) {}
        };

        if (loop) {
          play();
        } else {
          if (elementIsInViewport(videoRef.current)) {
            if (canPlay.current) {
              play();

              canPlay.current = false;
            }
          } else {
            canPlay.current = true;
          }
        }
      }
    };

    update();

    window.addEventListener('scroll', update, {
      passive: true,
    });
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, []);

  return (
    <>
      <video
        ref={videoRef}
        src={src}
        muted
        playsInline
        preload="auto"
        disablePictureInPicture
        loop={loop}
        poster={poster}
      />
    </>
  );
};
